import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import * as GameActionCreators from '../../actions/GameActionCreators';
import IconButton from '../../components/common/IconButton';
import classes from './Terms.module.css';

const Terms = () => {
  const dispatch = useDispatch();
  const { showTerms } = useSelector((state) => state.game);
  const { t } = useTranslation();

  const onClose = () => {
    dispatch(GameActionCreators.showTerms(false));
  };

  if (!showTerms) {
    return null;
  }
  return (
    <div className={classes.GuideContainer}>
      <div className={classes.Title}>
        <h1 className={classes.H1}>{t('terms.title')}</h1>
        <IconButton onClick={onClose}>
          <FaTimes />
        </IconButton>
      </div>
      <h2 className={classes.H2}>{t('terms.terms.title')}</h2>
      <p>{t('terms.terms.p1')}</p>
      <p>{t('terms.terms.p2')}</p>
      <p>{t('terms.terms.p3')}</p>
      <p>{t('terms.terms.p4')}</p>
      <p>{t('terms.terms.p5')}</p>
      <p>{t('terms.terms.p6')}</p>
      <p>{t('terms.terms.p7')}</p>
      <h2 className={classes.H2}>{t('terms.privacy.title')}</h2>
      <p>{t('terms.privacy.p1')}</p>
      <p>{t('terms.privacy.p2')}</p>
      <p>{t('terms.privacy.p3')}</p>
      <p>{t('terms.privacy.p4')}</p>
      <h2 className={classes.H2}>{t('terms.cookies.title')}</h2>
      <p>{t('terms.cookies.p1')}</p>
      <p>
        {t('terms.cookies.p2')}
        <a
          href="https://policies.google.com/privacy/partners?hl=fi"
          target="_blank"
          rel="noreferrer"
        >
          {t('terms.cookies.link')}
        </a>
        .
      </p>
      <p>{t('terms.cookies.p3')}</p>
    </div>
  );
};

export default function App() {
  return (
    <Suspense fallback="loading">
      <Terms />
    </Suspense>
  );
}
