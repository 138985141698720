import React from 'react';
import { useSelector } from 'react-redux';
import Main from '../Main/Main';
import Settings from '../Settings/Settings';
import Log from '../Log/Log';
import Pause from '../Pause/Pause';

const App = () => {
  const activeScene = useSelector((state) => state.game.activeScene);
  switch (activeScene) {
    case 'game':
      return <Main />;
    case 'settings':
      return <Settings />;
    case 'log':
      return <Log />;
    case 'pause':
      return <Pause />;
    default:
      return <Main />;
  }
};

export default App;
