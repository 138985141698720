import React, { useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import ReactCountryFlag from 'react-country-flag';
import classes from './ChooseFlag.module.css';

const ChooseFlag = ({ selected, options, onSelected }) => {
  const [optionsOpen, setOptionsOpen] = useState(false);

  const onOpenOptions = () => {
    setOptionsOpen(!optionsOpen);
  };

  const onOptionSelected = (option) => {
    onSelected(option);
    setOptionsOpen(!optionsOpen);
  };

  const renderChooseFlagOptions = () => {
    if (optionsOpen) {
      return (
        <div className={classes.ChooseFlagOptions}>
          {options.map((option) => {
            if (option !== selected) {
              return (
                <div
                  key={option}
                  className={classes.ChooseFlagOption}
                  onClick={() => onOptionSelected(option)}
                  aria-hidden="true"
                >
                  <ReactCountryFlag
                    countryCode={option}
                    svg
                    style={{
                      width: 'unset',
                      verticalAlign: 'unset',
                    }}
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classes.Container}>
      <div
        className={classes.ChooseFlag}
        onClick={onOpenOptions}
        aria-hidden="true"
      >
        <div className={classes.ChooseFlagSelected}>
          <FaCaretDown />
          <ReactCountryFlag
            countryCode={selected}
            svg
            style={{
              width: 'unset',
              verticalAlign: 'unset',
              marginLeft: '3px',
            }}
          />
        </div>
        {renderChooseFlagOptions()}
      </div>
    </div>
  );
};

export default ChooseFlag;
