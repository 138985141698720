import React from 'react';
import { useSelector } from 'react-redux';
import classes from './Main.module.css';
import Game from '../Game/Game';
import TurnLog from '../TurnLog/TurnLog';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Terms from '../Terms/Terms';
import Guide from '../Guide/Guide';
import MobileMenubar from '../MobileMenubar/MobileMenubar';
import { TEAM_COLORS } from '../../Constants';

const Main = () => {
  const { teams, currentTeam, turnPause, roundPause, finished } = useSelector(
    (state) => state.game,
  );

  const renderLatestTurnLog = () => {
    if (turnPause || roundPause || finished) {
      return <TurnLog summary />;
    }
    return null;
  };

  return (
    <div
      className={classes.Container}
      style={{ backgroundColor: TEAM_COLORS[teams[currentTeam].colorIndex] }}
    >
      <Header />
      <Game />
      {renderLatestTurnLog()}
      <Guide />
      <Terms />
      <Footer />
      <MobileMenubar />
    </div>
  );
};

export default Main;
