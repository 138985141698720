/* eslint-disable no-param-reassign */
import {
  WORD_CHANGED,
  GAME_STARTED,
  TIME_UPDATED,
  TURN_END,
  ROUND_START,
  TURN_START,
  WORD_GUESSED,
  WORD_PASSED,
  ACTIVE_SCENE_CHANGED,
  LOG_UPDATED,
  RESET_NOW,
  SHOW_TERMS,
  HISTORY_EDIT_MODE_CHANGED,
  LOG_EDITED,
} from './types';
import { CURRENT_SITE_KEY } from '../Constants';
import rawFi from '../assets/FI_all.txt'; // FIXME: download dynamically
import rawEn from '../assets/EN_all.txt';

export const activeScene = (scene) => ({
  type: ACTIVE_SCENE_CHANGED,
  payload: scene,
});

export const reset = () => ({
  type: RESET_NOW,
  payload: true,
});

const nextWordExec = (dispatch, getState) => {
  const { words, wordCount } = getState().game;
  const newWords = [];
  for (let i = 0; i < wordCount; i += 1) {
    // TODO jos sanat loppuu pitää alkaa alusta
    newWords.push(words.splice(Math.floor(Math.random() * words.length), 1)[0]);
  }
  dispatch({
    type: WORD_CHANGED,
    payload: { newWords, words },
  });
};

export const nextWord = () => (dispatch, getState) => {
  nextWordExec(dispatch, getState);
};

const endTurnExec = (dispatch, getState) => {
  const {
    teams,
    currentTeam,
    currentRound,
    totalRounds,
    gameMode,
    totalPoints,
    time,
  } = getState().game;

  let finished = false;
  const lastTeam = teams.length === currentTeam + 1;
  teams.forEach((team, index) => {
    if (team.roundScore > 0) {
      team.totalScore += team.roundScore;
    }
    if (gameMode === 'time' && index === currentTeam) {
      team.totalTime += time;
    }
    if (gameMode === 'points' && team.totalScore >= totalPoints && lastTeam) {
      finished = true;
    }
  });

  const lastRound = currentRound === totalRounds;
  let roundPause = lastTeam;
  if (gameMode !== 'points') {
    finished = lastRound && lastTeam;
    roundPause = !lastRound && lastTeam;
  }
  const turnPause = !finished && !roundPause;

  dispatch({
    type: TURN_END,
    payload: { teams, turnPause, roundPause, finished },
  });
};

export const endTurn = () => (dispatch, getState) => {
  endTurnExec(dispatch, getState);
};

const calcExplanationTime = (log, time) => {
  if (log && log.length > 0) {
    const lastRound = log[log.length - 1];
    if (lastRound && lastRound.length > 0) {
      const lastTurn = lastRound[lastRound.length - 1];
      if (lastTurn && lastTurn.length > 0) {
        let previousExecutionsThisTurn = 0;
        for (let i = 0; i < lastTurn.length; i += 1) {
          previousExecutionsThisTurn += lastTurn[i].explanationTime;
        }
        return time - previousExecutionsThisTurn;
      }
      return time;
    }
    return time;
  }
  return time;
};

export const wordNotGuessed = () => (dispatch, getState) => {
  const {
    teams,
    currentTeam,
    freepassLeft,
    log,
    currentWords,
    skipMode,
    skipMinusTime,
    gameMode,
    time,
    roundPointsLeft,
  } = getState().game;
  const team = teams[currentTeam];

  let points = 0;
  let newFreepassLeft = freepassLeft;
  let newTime = time;
  let newRoundPointsLeft = roundPointsLeft;

  if (gameMode === 'timerun') {
    newTime -= skipMinusTime;
  } else if (skipMode === 'freepass') {
    newFreepassLeft = freepassLeft > 0 ? freepassLeft - 1 : 0;
    if (freepassLeft === 0) {
      if (gameMode === 'time') {
        newRoundPointsLeft += 1;
      }
      team.roundScore -= 1;
      points = -1;
    }
  } else if (skipMode === 'always') {
    if (gameMode === 'time') {
      newRoundPointsLeft += 1;
    }
    team.roundScore -= 1;
    points = -1;
  }

  const explanationTime = calcExplanationTime(log, time);

  const currentLogRound = log[log.length - 1];
  const currentLogTurn = currentLogRound[currentLogRound.length - 1];
  currentLogTurn.push({
    words: currentWords,
    team: currentTeam,
    points,
    explanationTime,
  });

  dispatch({
    type: WORD_PASSED,
    payload: {
      teams,
      freepassLeft: newFreepassLeft,
      log: [...log],
      time: newTime,
      roundPointsLeft: newRoundPointsLeft,
    },
  });
};

export const wordGuessed = (currentTeam) => (dispatch, getState) => {
  const {
    teams,
    log,
    currentWords,
    gameMode,
    timeBonus,
    time,
    roundPointsLeft,
    onGoing,
  } = getState().game;
  const team = teams[currentTeam];
  team.roundScore += 1;

  let newTime = time;
  let newRoundPointsLeft = roundPointsLeft;
  let newOnGoing = onGoing;
  if (gameMode === 'timerun') {
    newTime += timeBonus;
  } else if (gameMode === 'time') {
    newRoundPointsLeft -= 1;
    if (newRoundPointsLeft === 0) {
      newOnGoing = false;
      dispatch(endTurn());
    }
  }

  const explanationTime = calcExplanationTime(log, time);

  const currentLogRound = log[log.length - 1];
  const currentLogTurn = currentLogRound[currentLogRound.length - 1];
  currentLogTurn.push({
    words: currentWords,
    team: currentTeam,
    points: 1,
    explanationTime,
  });

  dispatch({
    type: WORD_GUESSED,
    payload: {
      teams,
      log: [...log],
      time: newTime,
      roundPointsLeft: newRoundPointsLeft,
      onGoing: newOnGoing,
    },
  });
};

function shuffle(a) {
  let j;
  let x;
  let i;
  for (i = a.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

function findSuitableWords(lines, wordLength) {
  const words = [];
  for (let i = 0; i < lines.length; i += 1) {
    if (
      lines[i].length >= wordLength.from &&
      lines[i].length <= wordLength.to
    ) {
      words.push(lines[i]);
      if (words.length >= 5000) {
        break;
      }
    }
  }
  return words;
}

export const startGame = () => (dispatch, getState) => {
  const { wordLength } = getState().game;
  const raw = CURRENT_SITE_KEY === 'fi' ? rawFi : rawEn;
  fetch(raw)
    .then((r) => r.text())
    .then((text) => {
      let lines = text.split('\n');
      lines = shuffle(lines);
      const words = findSuitableWords(lines, wordLength);
      dispatch({
        type: GAME_STARTED,
        payload: words,
      });
    });
};

export const secondPassed = () => (dispatch, getState) => {
  const {
    allGuess,
    randomAllGuess,
    currentTeam,
    roundTime,
    time,
    log,
    currentWords,
    gameMode,
  } = getState().game;
  if (gameMode === 'time') {
    dispatch({
      type: TIME_UPDATED,
      payload: {
        time: time + 1,
        onGoing: true,
        waitingLast: false,
      },
    });
  } else {
    if (
      time <= 0 &&
      (allGuess !== 'end' ||
        (allGuess === 'random' && randomAllGuess !== 'end'))
    ) {
      const currentLogRound = log[log.length - 1];
      const currentLogTurn = currentLogRound[currentLogRound.length - 1];
      currentLogTurn.push({
        words: currentWords,
        team: currentTeam,
        points: 0,
      });
      dispatch({ type: LOG_UPDATED, payload: [...log] });
      dispatch(endTurn());
      nextWordExec(dispatch, getState);
    }
    dispatch({
      type: TIME_UPDATED,
      payload: {
        time: time > 0 ? time - 1 : roundTime,
        onGoing: time > 0,
        waitingLast: time <= 0,
      },
    });
  }
};

export const nextTurn = () => (dispatch, getState) => {
  const { teams, currentTeam, currentRound, roundPause, log } = getState().game;
  teams.forEach((team) => {
    team.roundScore = 0;
  });
  if (roundPause) {
    log.push([]);
    dispatch({
      type: ROUND_START,
      payload: {
        teams,
        currentTeam: 0,
        currentRound: currentRound + 1,
      },
    });
  }

  log[log.length - 1].push([]);
  dispatch({
    type: TURN_START,
    payload: {
      teams,
      currentTeam: teams.length === currentTeam + 1 ? 0 : currentTeam + 1,
      log: [...log],
    },
  });
};

export const showTerms = (value) => ({
  type: SHOW_TERMS,
  payload: value,
});

export const toggleEditMode = () => (dispatch, getState) => {
  const { historyEditMode } = getState().game;
  dispatch({
    type: HISTORY_EDIT_MODE_CHANGED,
    payload: !historyEditMode,
  });
};

export const toggleLogItemPoints = (logItemIndex, turnIndex, roundIndex) => (
  dispatch,
  getState,
) => {
  const { log, teams, finished, roundPause, turnPause } = getState().game;
  const latestTurn =
    roundIndex === log.length - 1 && turnIndex === log[roundIndex].length - 1;
  const logItem = log[roundIndex][turnIndex][logItemIndex];
  if (logItem.points === 1) {
    logItem.points = -1;
    if (latestTurn) {
      teams[logItem.team].roundScore -= 2;
    }
    if (!latestTurn || (latestTurn && (finished || roundPause || turnPause))) {
      teams[logItem.team].totalScore -= 2;
    }
  } else {
    logItem.points += 1;
    if (latestTurn) {
      teams[logItem.team].roundScore += 1;
    }
    if (!latestTurn || (latestTurn && (finished || roundPause || turnPause))) {
      teams[logItem.team].totalScore += 1;
    }
  }
  dispatch({
    type: LOG_EDITED,
    payload: { log: [...log], teams },
  });
};

export const toggleLogItemTeam = (logItemIndex, turnIndex, roundIndex) => (
  dispatch,
  getState,
) => {
  const { log, teams, finished, roundPause, turnPause } = getState().game;
  const latestTurn =
    roundIndex === log.length - 1 && turnIndex === log[roundIndex].length - 1;
  const logItem = log[roundIndex][turnIndex][logItemIndex];
  if (logItem.team === teams.length - 1) {
    if (latestTurn) {
      teams[logItem.team].roundScore -= logItem.points;
      teams[0].roundScore += logItem.points;
    }
    if (!latestTurn || (latestTurn && (finished || roundPause || turnPause))) {
      teams[logItem.team].totalScore -= logItem.points;
      teams[0].totalScore += logItem.points;
    }
    logItem.team = 0;
  } else {
    if (latestTurn) {
      teams[logItem.team].roundScore -= logItem.points;
      teams[logItem.team + 1].roundScore += logItem.points;
    }
    if (!latestTurn || (latestTurn && (finished || roundPause || turnPause))) {
      teams[logItem.team].totalScore -= logItem.points;
      teams[logItem.team + 1].totalScore += logItem.points;
    }
    logItem.team += 1;
  }
  dispatch({
    type: LOG_EDITED,
    payload: { log: [...log], teams },
  });
};
