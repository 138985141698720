/* eslint-disable import/prefer-default-export */
export const TEAM_COLORS = [
  '#8500cc',
  '#e9009d',
  '#ff7f4a',
  '#ffc044',
  '#005cff',
  '#00bba4',
  '#9c7cac',
  '#7e7383',
  '#be0000',
  '#7a9ebb',
  '#3b2a45',
  '#211825',
  '#4a7fa4',
  '#4d4352',
  '#665700',
  '#884600',
  '#5897c3',
  '#ff3b00',
  '#b5008d',
  '#405c1d',
  '#b6907c',
  '#bf5846',
];
export const ALL_GUESS = ['always', 'end', 'never', 'random', 'lastRound'];
export const SKIP_MODES = ['always', 'never', 'freepass'];

const convertHexToRGBA = (hex, opacity) => {
  const tempHex = hex.replace('#', '');
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${opacity})`;
};

export const opacityTeamColor = (index, opacity) =>
  convertHexToRGBA(TEAM_COLORS[index], opacity);

export const SITES = {
  fi: {
    siteName: 'selityspeli',
    url: 'https://www.selityspeli.com/',
    flag: 'fi',
    wordsFile: 'FI_all.txt',
    wordsLink: 'https://www.kielitoimistonsanakirja.fi/',
    wordsSource: 'https://www.kotus.fi/',
    default: true,
  },
  en: {
    siteName: 'explanationgame',
    url: 'https://www.explanationgame.com/',
    flag: 'gb',
    wordsFile: 'EN_all.txt',
    wordsLink: 'http://wordnetweb.princeton.edu/perl/webwn?s=',
    wordsSource: 'https://wordnet.princeton.edu/',
    default: false,
  },
};

const url = window.location.href;
let site = Object.keys(SITES).find((key) => url.includes(SITES[key].siteName));
if (!site) {
  site = Object.keys(SITES).find((key) => SITES[key].default);
}

export const CURRENT_SITE_KEY = site;
