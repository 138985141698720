/* eslint-disable no-param-reassign */
import {
  ALL_GUESS_CHANGED,
  TOTAL_ROUNDS_CHANGED,
  ROUND_TIME_CHANGED,
  FREEPASS_LIMIT_CHANGED,
  WORD_COUNT_CHANGED,
  WORD_MIN_LENGTH_CHANGED,
  WORD_MAX_LENGTH_CHANGED,
  TEAM_COUNT_CHANGED,
  TEAM_NAME_CHANGED,
  TEAM_COLOR_CHANGED,
  TOTAL_POINTS_CHANGED,
  GAME_MODE_CHANGED,
  SKIP_MODE_CHANGED,
  TIME_BONUS_CHANGED,
  SKIP_MINUS_TIME_CHANGED,
  ROUND_POINTS_CHANGED,
} from './types';
import { TEAM_COLORS } from '../Constants';

export const changeAllGuess = (option) => ({
  type: ALL_GUESS_CHANGED,
  payload: option,
});

export const changeSkipMode = (option) => ({
  type: SKIP_MODE_CHANGED,
  payload: option,
});

export const changeTeamColor = (teamIndex, colorIndex) => (
  dispatch,
  getState,
) => {
  const { teams } = getState().game;
  teams[teamIndex].colorIndex = colorIndex;
  dispatch({
    type: TEAM_COLOR_CHANGED,
    payload: teams,
  });
};

export const changeTotalRounds = (rounds) => ({
  type: TOTAL_ROUNDS_CHANGED,
  payload: rounds,
});

export const changeTotalPoints = (points) => ({
  type: TOTAL_POINTS_CHANGED,
  payload: points,
});

export const changeRoundTime = (time) => ({
  type: ROUND_TIME_CHANGED,
  payload: time,
});

export const changeFreepassLimit = (freepassLimit) => ({
  type: FREEPASS_LIMIT_CHANGED,
  payload: freepassLimit,
});

export const changeWordCount = (wordCount) => ({
  type: WORD_COUNT_CHANGED,
  payload: wordCount,
});

export const changeWordLengthMin = (min) => ({
  type: WORD_MIN_LENGTH_CHANGED,
  payload: min,
});

export const changeWordLengthMax = (max) => ({
  type: WORD_MAX_LENGTH_CHANGED,
  payload: max,
});

const notUsedColor = (index, teams) => {
  let notUsed = true;
  teams.forEach((team) => {
    if (team.colorIndex === index) {
      notUsed = false;
    }
  });
  return notUsed;
};

export const addTeam = () => (dispatch, getState) => {
  const { teams } = getState().game;
  let nextUnusedIndex = 0;
  for (let i = 0; i < TEAM_COLORS.length; i += 1) {
    if (notUsedColor(i, teams)) {
      nextUnusedIndex = i;
      break;
    }
  }

  teams.push({
    id: teams.length + 1,
    name: '',
    roundScore: 0,
    totalScore: 0,
    colorIndex: nextUnusedIndex,
  });

  dispatch({
    type: TEAM_COUNT_CHANGED,
    payload: teams,
  });
};

export const removeTeam = () => (dispatch, getState) => {
  const { teams } = getState().game;
  teams.pop();
  dispatch({
    type: TEAM_COUNT_CHANGED,
    payload: teams,
  });
};

export const changeTeamName = (teamIndex, newName) => (dispatch, getState) => {
  const { teams } = getState().game;
  teams[teamIndex].name = newName;
  dispatch({
    type: TEAM_NAME_CHANGED,
    payload: teams,
  });
};

export const changeGameMode = (mode) => ({
  type: GAME_MODE_CHANGED,
  payload: mode,
});

export const changeTimeBonus = (seconds) => ({
  type: TIME_BONUS_CHANGED,
  payload: seconds,
});

export const changeSkipMinusTime = (seconds) => ({
  type: SKIP_MINUS_TIME_CHANGED,
  payload: seconds,
});

export const changeRoundPoints = (points) => ({
  type: ROUND_POINTS_CHANGED,
  payload: points,
});
