import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaTimes, FaMinus, FaPlus } from 'react-icons/fa';
import * as SettingsActionCreators from '../../actions/SettingsActionCreators';
import * as GameActionCreators from '../../actions/GameActionCreators';
import IconButton from '../../components/common/IconButton';
import ChooseBox from '../../components/ChooseBox';
import ChooseColor from '../../components/ChooseColor';
import SettingInput from '../../components/SettingInput';
import Header from '../Header/Header';
import Button from '../../components/common/Button';
import Guide from '../Guide/Guide';
import Terms from '../Terms/Terms';
import Footer from '../Footer/Footer';
import MobileMenubar from '../MobileMenubar/MobileMenubar';
import classes from './Settings.module.css';
import { TEAM_COLORS, SKIP_MODES, ALL_GUESS } from '../../Constants';

const Settings = () => {
  const dispatch = useDispatch();
  const {
    totalRounds,
    roundTime,
    teams,
    wordLength,
    freepassLimit,
    allGuess,
    wordCount,
    currentTeam,
    totalPoints,
    gameMode,
    skipMode,
    roundPoints,
    skipMinusTime,
    timeBonus,
  } = useSelector((state) => state.game);
  const { t } = useTranslation();

  const onClose = () => {
    dispatch(GameActionCreators.activeScene('game'));
  };

  const onReset = () => {
    dispatch(GameActionCreators.activeScene('game'));
    dispatch(GameActionCreators.startGame());
  };

  const onGuessAllChanged = (option) => {
    dispatch(SettingsActionCreators.changeAllGuess(option));
  };

  const onSkipModeChanged = (option) => {
    dispatch(SettingsActionCreators.changeSkipMode(option));
  };

  const onTeamColorChanged = (teamIndex, color) => {
    dispatch(
      SettingsActionCreators.changeTeamColor(
        teamIndex,
        TEAM_COLORS.indexOf(color),
      ),
    );
  };

  const onAddTeam = () => {
    dispatch(SettingsActionCreators.addTeam());
  };

  const onRemoveTeam = () => {
    dispatch(SettingsActionCreators.removeTeam());
  };

  const notUsedColor = (index) => {
    let notUsed = true;
    teams.forEach((team) => {
      if (team.colorIndex === index) {
        notUsed = false;
      }
    });
    return notUsed;
  };

  const notUsedColors = () =>
    TEAM_COLORS.filter((color, index) => notUsedColor(index));

  const renderTeam = (team, index) => (
    <div key={index} className={classes.Team}>
      {t('settings.team', { id: index + 1 })}
      <div className={classes.TeamInfos}>
        <input
          className={classes.TeamNameInput}
          type="text"
          value={team.name}
          placeholder={t('settings.teamplaceholder', { id: index + 1 })}
          onChange={(e) =>
            dispatch(
              SettingsActionCreators.changeTeamName(index, e.target.value),
            )
          }
        />
        <ChooseColor
          key={index}
          selected={TEAM_COLORS[team.colorIndex]}
          colors={notUsedColors()}
          teamIndex={index}
          onSelected={onTeamColorChanged}
        />
      </div>
    </div>
  );

  const renderTeams = () => teams.map((team, index) => renderTeam(team, index));

  const renderRemoveTeam = () => {
    if (teams.length > 1) {
      return (
        <IconButton onClick={onRemoveTeam}>
          <FaMinus />
        </IconButton>
      );
    }
    return <div />;
  };

  const renderGameTarget = () => {
    if (gameMode !== 'points') {
      return (
        <div className={classes.GameTarget}>
          {t('settings.roundcount')}
          <SettingInput
            value={totalRounds}
            onChange={(e) =>
              dispatch(SettingsActionCreators.changeTotalRounds(e.target.value))
            }
          />
        </div>
      );
    }
    return (
      <div className={classes.GameTarget}>
        {t('settings.pointgoal')}
        <SettingInput
          value={totalPoints}
          onChange={(e) =>
            dispatch(SettingsActionCreators.changeTotalPoints(e.target.value))
          }
        />
      </div>
    );
  };

  const renderFreepassLimit = () => {
    if (skipMode === 'freepass') {
      return (
        <div className={classes.Setting}>
          {t('settings.freepasses')}
          <SettingInput
            value={freepassLimit}
            onChange={(e) =>
              dispatch(
                SettingsActionCreators.changeFreepassLimit(e.target.value),
              )
            }
          />
        </div>
      );
    }
    return null;
  };

  const renderTimeBonus = () => {
    if (gameMode === 'timerun') {
      return (
        <div className={classes.Setting}>
          {t('settings.correcttimebonus')}
          <SettingInput
            value={timeBonus}
            onChange={(e) =>
              dispatch(SettingsActionCreators.changeTimeBonus(e.target.value))
            }
          />
        </div>
      );
    }
    return null;
  };

  const renderSkipPenalty = () => {
    if (gameMode === 'timerun') {
      return (
        <div className={classes.Setting}>
          {t('settings.passpenaltytime')}
          <SettingInput
            value={skipMinusTime}
            onChange={(e) =>
              dispatch(
                SettingsActionCreators.changeSkipMinusTime(e.target.value),
              )
            }
          />
        </div>
      );
    }
    return (
      <ChooseBox
        selected={skipMode}
        options={SKIP_MODES.reduce(
          (prev, current) => ({
            ...prev,
            [current]: t(`settings.skipmodes.${current}`),
          }),
          {},
        )}
        title={t('settings.skippenalty')}
        onSelected={onSkipModeChanged}
      />
    );
  };

  const renderRoundTime = () => {
    if (gameMode !== 'time') {
      return (
        <div className={classes.Setting}>
          {t('settings.roundtime')}
          <SettingInput
            value={roundTime}
            onChange={(e) =>
              dispatch(SettingsActionCreators.changeRoundTime(e.target.value))
            }
          />
        </div>
      );
    }
    return null;
  };

  const renderRoundPoints = () => {
    if (gameMode === 'time') {
      return (
        <div className={classes.Setting}>
          {t('settings.roundpoints')}
          <SettingInput
            value={roundPoints}
            onChange={(e) =>
              dispatch(SettingsActionCreators.changeRoundPoints(e.target.value))
            }
          />
        </div>
      );
    }
    return null;
  };

  const renderAllGuess = () => {
    if (gameMode !== 'time') {
      return (
        <ChooseBox
          selected={allGuess}
          options={ALL_GUESS.reduce(
            (prev, current) => ({
              ...prev,
              [current]: t(`settings.allguess.${current}`),
            }),
            {},
          )}
          title={t('settings.allguesstitle')}
          onSelected={onGuessAllChanged}
        />
      );
    }
    return null;
  };

  return (
    <div
      className={classes.Container}
      style={{ backgroundColor: TEAM_COLORS[teams[currentTeam].colorIndex] }}
    >
      <Header />
      <div className={classes.SettingsContainer}>
        <div className={classes.Title}>
          {t('settings.title')}
          <IconButton onClick={onClose}>
            <FaTimes />
          </IconButton>
        </div>
        <div className={classes.GameModeContainer}>
          <Button
            disabled={gameMode === 'rounds'}
            settingsMode
            onClick={() =>
              dispatch(SettingsActionCreators.changeGameMode('rounds'))
            }
          >
            {t('settings.roundgame')}
          </Button>
          <Button
            disabled={gameMode === 'points'}
            settingsMode
            onClick={() =>
              dispatch(SettingsActionCreators.changeGameMode('points'))
            }
          >
            {t('settings.pointgame')}
          </Button>
          <Button
            disabled={gameMode === 'time'}
            settingsMode
            onClick={() =>
              dispatch(SettingsActionCreators.changeGameMode('time'))
            }
          >
            {t('settings.timegame')}
          </Button>
          <Button
            disabled={gameMode === 'timerun'}
            settingsMode
            onClick={() =>
              dispatch(SettingsActionCreators.changeGameMode('timerun'))
            }
          >
            {t('settings.timerun')}
          </Button>
        </div>
        {renderGameTarget()}
        {renderRoundTime()}
        {renderRoundPoints()}
        {renderTimeBonus()}
        {renderSkipPenalty()}
        {renderFreepassLimit()}
        {renderAllGuess()}
        <div className={classes.Setting}>
          {t('settings.teamcount')}
          <div className={classes.TeamCountButtons}>
            {renderRemoveTeam()}
            <IconButton onClick={onAddTeam}>
              <FaPlus />
            </IconButton>
          </div>
        </div>
        {renderTeams()}
        <div className={classes.Setting}>
          {t('settings.wordcount')}
          <SettingInput
            value={wordCount}
            onChange={(e) =>
              dispatch(SettingsActionCreators.changeWordCount(e.target.value))
            }
          />
        </div>
        <div className={classes.Setting}>
          {t('settings.wordlength')}
          <div className={classes.wordLength}>
            <SettingInput
              value={wordLength.from}
              onChange={(e) =>
                dispatch(
                  SettingsActionCreators.changeWordLengthMin(e.target.value),
                )
              }
            />
            -
            <SettingInput
              value={wordLength.to}
              onChange={(e) =>
                dispatch(
                  SettingsActionCreators.changeWordLengthMax(e.target.value),
                )
              }
            />
          </div>
        </div>
        <div className={classes.BottomButtomContainer}>
          <Button settingsButton onClick={onReset}>
            {t('settings.startnew')}
          </Button>
          <Button settingsButton onClick={onClose}>
            {t('settings.ok')}
          </Button>
        </div>
      </div>
      <Guide />
      <Terms />
      <Footer />
      <MobileMenubar />
    </div>
  );
};

export default function App() {
  return (
    <Suspense fallback="loading">
      <Settings />
    </Suspense>
  );
}
