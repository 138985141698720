import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as GameActionCreators from '../../actions/GameActionCreators';
import GameHeader from '../GameHeader/GameHeader';
import Scores from '../Scores/Scores';
import Button from '../../components/common/Button';
import classes from './Game.module.css';
import { opacityTeamColor } from '../../Constants';

const Game = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    onGoing,
    waitingLast,
    roundPause,
    turnPause,
    finished,
    currentRound,
    totalRounds,
    teams,
    currentTeam,
    currentWords,
    allGuess,
    randomAllGuess,
    gameMode,
  } = useSelector((state) => state.game);

  const backgroundOpacityTeamColor = opacityTeamColor(
    teams[currentTeam].colorIndex,
    0.7,
  );

  const onSkipWord = () => {
    if (
      (allGuess === 'end' && waitingLast) ||
      (allGuess === 'random' && randomAllGuess === 'end' && waitingLast)
    ) {
      dispatch(GameActionCreators.endTurn());
    } else {
      dispatch(GameActionCreators.wordNotGuessed());
    }
    dispatch(GameActionCreators.nextWord());
  };

  const onWordGuessed = (index) => {
    dispatch(GameActionCreators.wordGuessed(index));
    dispatch(GameActionCreators.nextWord());
    if (
      (allGuess === 'end' && waitingLast) ||
      (allGuess === 'random' && randomAllGuess === 'end' && waitingLast)
    ) {
      dispatch(GameActionCreators.endTurn());
    }
  };

  const onNextState = () => {
    if ((roundPause || turnPause) && !finished) {
      dispatch(GameActionCreators.nextTurn());
    } else {
      dispatch(GameActionCreators.startGame());
    }
  };

  const showGameButtons = (allGuessMode) => {
    if (gameMode === 'time') {
      return onGoing;
    }
    return (
      (allGuessMode === 'end' ||
        allGuessMode === 'never' ||
        (allGuessMode === 'lastRound' && currentRound !== totalRounds)) &&
      onGoing
    );
  };

  const renderGameButtons = () => {
    if (
      showGameButtons(allGuess) ||
      (allGuess === 'random' && showGameButtons(randomAllGuess))
    ) {
      return (
        <div className={classes.WordButtonContainer}>
          <Button
            onClick={onSkipWord}
            colorIndex={teams[currentTeam].colorIndex}
          >
            {t('game.button.pass')}
          </Button>
          <Button
            onClick={() => onWordGuessed(currentTeam)}
            colorIndex={teams[currentTeam].colorIndex}
          >
            {t('game.button.correct')}
          </Button>
        </div>
      );
    }
    return null;
  };

  const renderWhoGuessedButton = (team, index) => (
    <Button
      key={index}
      onClick={() => onWordGuessed(index)}
      colorIndex={teams[currentTeam].colorIndex}
    >
      {team.name ? team.name : t('game.button.team', { id: team.id })}
    </Button>
  );

  const showWhoGuessed = (allGuessMode) => {
    if (gameMode === 'time') {
      return false;
    }
    return (
      (allGuessMode === 'end' && waitingLast) ||
      ((allGuessMode === 'always' ||
        (allGuessMode === 'lastRound' && currentRound === totalRounds)) &&
        onGoing)
    );
  };

  const renderWhoGuessedButtons = () => {
    if (
      showWhoGuessed(allGuess) ||
      (allGuess === 'random' && showWhoGuessed(randomAllGuess))
    ) {
      const whoGuessedButtons = teams.map((team, index) =>
        renderWhoGuessedButton(team, index),
      );
      return (
        <>
          <div className={classes.WordButtonContainer}>{whoGuessedButtons}</div>
          <div className={classes.WordButtonContainer}>
            <Button
              onClick={onSkipWord}
              colorIndex={teams[currentTeam].colorIndex}
            >
              {t('game.button.pass')}
            </Button>
          </div>
        </>
      );
    }
    return null;
  };

  const renderNextStateButton = () => {
    if (
      !onGoing &&
      (allGuess === 'never' ||
        allGuess === 'always' ||
        allGuess === 'lastRound' ||
        (allGuess === 'random' && randomAllGuess !== 'end') ||
        (allGuess === 'end' && !waitingLast) ||
        (allGuess === 'random' && randomAllGuess === 'end' && !waitingLast))
    ) {
      let buttonText = t('game.button.start');
      if (finished) {
        buttonText = t('game.button.again');
      } else if (roundPause) {
        buttonText = t('game.button.nextround');
      } else if (turnPause) {
        buttonText = t('game.button.nextteam');
      }
      return (
        <div className={classes.WordButtonContainer}>
          <Button
            onClick={() => onNextState()}
            colorIndex={teams[currentTeam].colorIndex}
          >
            {buttonText}
          </Button>
        </div>
      );
    }
    return null;
  };

  const renderWord = (word, index) => (
    <div key={index} className={classes.word}>
      {word}
    </div>
  );

  const renderWords = () => {
    if (!turnPause && !roundPause && !finished) {
      return (
        <div className={classes.WordsContainer}>
          {currentWords.map((word, index) => renderWord(word, index))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classes.WhiteContainer}>
      <div
        className={classes.Container}
        style={{
          backgroundColor: backgroundOpacityTeamColor,
        }}
      >
        <GameHeader />
        {renderWords()}
        <Scores />
        {renderWhoGuessedButtons()}
        {renderGameButtons()}
        {renderNextStateButton()}
      </div>
    </div>
  );
};

export default function App() {
  return (
    <Suspense fallback="loading">
      <Game />
    </Suspense>
  );
}
