import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as GameActionCreators from '../../actions/GameActionCreators';
import Header from '../Header/Header';
import Button from '../../components/common/Button';
import Guide from '../Guide/Guide';
import Terms from '../Terms/Terms';
import Footer from '../Footer/Footer';
import MobileMenubar from '../MobileMenubar/MobileMenubar';
import classes from './Pause.module.css';
import { TEAM_COLORS } from '../../Constants';

const Pause = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { teams, currentTeam, time } = useSelector((state) => state.game);

  const onClose = () => {
    dispatch(GameActionCreators.activeScene('game'));
  };

  const onReset = () => {
    dispatch(GameActionCreators.activeScene('game'));
    dispatch(GameActionCreators.startGame());
  };

  const onFactorySettings = () => {
    dispatch(GameActionCreators.reset());
  };

  return (
    <div
      className={classes.Container}
      style={{ backgroundColor: TEAM_COLORS[teams[currentTeam].colorIndex] }}
    >
      <Header />
      <div className={classes.PauseContainer}>
        <div className={classes.Title}>{t('pause.timeleft', { time })}</div>
        <div className={classes.ButtonsContainer}>
          <Button settingsButton onClick={onClose}>
            {t('pause.return')}
          </Button>
          <Button settingsButton onClick={onReset}>
            {t('pause.restart')}
          </Button>
          <Button settingsButton onClick={onFactorySettings}>
            {t('pause.reset')}
          </Button>
        </div>
      </div>
      <Guide />
      <Terms />
      <Footer />
      <MobileMenubar />
    </div>
  );
};

export default function App() {
  return (
    <Suspense fallback="loading">
      <Pause />
    </Suspense>
  );
}
