import React from 'react';
import classes from './SettingInput.module.css';

const SettingInput = ({ value, onChange }) => (
  <input
    className={classes.NumberInput}
    type="number"
    min="1"
    step="1"
    value={value}
    onChange={onChange}
  />
);

export default SettingInput;
