import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  FaCog,
  FaClipboardList,
  FaPauseCircle,
  FaPlayCircle,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import * as GameActionCreators from '../../actions/GameActionCreators';
import IconButton from '../../components/common/IconButton';
import classes from './MobileMenubar.module.css';
import { TEAM_COLORS } from '../../Constants';

const MobileMenubar = () => {
  const dispatch = useDispatch();
  const { activeScene, teams, currentTeam } = useSelector(
    (state) => state.game,
  );
  const { t } = useTranslation();

  const onGame = () => {
    dispatch(GameActionCreators.activeScene('game'));
  };

  const onPause = () => {
    dispatch(GameActionCreators.activeScene('pause'));
  };

  const onLog = () => {
    dispatch(GameActionCreators.activeScene('log'));
  };

  const onSettings = () => {
    dispatch(GameActionCreators.activeScene('settings'));
  };

  const renderTitle = (title, scene) => (
    <h4
      className={[
        classes.Title,
        activeScene === scene ? '' : classes.HideTitle,
      ].join(' ')}
    >
      {title}
    </h4>
  );

  return (
    <div
      className={classes.Container}
      style={{ backgroundColor: TEAM_COLORS[teams[currentTeam].colorIndex] }}
    >
      <IconButton
        onClick={onGame}
        fade={activeScene !== 'game'}
        selectedMenu={activeScene === 'game'}
      >
        <FaPlayCircle />
        {renderTitle(t('mobilemenubar.play'), 'game')}
      </IconButton>
      <IconButton
        onClick={onPause}
        fade={activeScene !== 'pause'}
        selectedMenu={activeScene === 'pause'}
      >
        <FaPauseCircle />
        {renderTitle(t('mobilemenubar.pause'), 'pause')}
      </IconButton>
      <IconButton
        onClick={onLog}
        fade={activeScene !== 'log'}
        selectedMenu={activeScene === 'log'}
      >
        <FaClipboardList />
        {renderTitle(t('mobilemenubar.log'), 'log')}
      </IconButton>
      <IconButton
        onClick={onSettings}
        fade={activeScene !== 'settings'}
        selectedMenu={activeScene === 'settings'}
      >
        <FaCog />
        {renderTitle(t('mobilemenubar.settings'), 'settings')}
      </IconButton>
    </div>
  );
};

export default function App() {
  return (
    <Suspense fallback="loading">
      <MobileMenubar />
    </Suspense>
  );
}
