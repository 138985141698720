import React, { useState } from 'react';
import classes from './ChooseColor.module.css';

const ChooseColor = ({ selected, teamIndex, colors, onSelected }) => {
  const [optionsOpen, setOptionsOpen] = useState(false);

  const onOpenOptions = () => {
    setOptionsOpen(!optionsOpen);
  };

  const onOptionSelected = (color) => {
    onSelected(teamIndex, color);
    setOptionsOpen(!optionsOpen);
  };

  const renderColorOptions = () => {
    if (optionsOpen) {
      return (
        <div className={classes.ColorSelectorOptions}>
          {colors.map((option) => (
            <div
              key={option}
              className={classes.ColorOption}
              onClick={() => onOptionSelected(option)}
              aria-hidden="true"
              style={{ backgroundColor: option }}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={classes.ColorSelector}
      onClick={onOpenOptions}
      aria-hidden="true"
    >
      <div
        className={classes.ColorSelected}
        style={{ backgroundColor: selected }}
      />
      {renderColorOptions()}
    </div>
  );
};

export default ChooseColor;
