import React, { Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { FaCog, FaClipboardList, FaPauseCircle, FaEdit } from 'react-icons/fa';
import { Trans, useTranslation } from 'react-i18next';
import * as GameActionCreators from '../../actions/GameActionCreators';
import classes from './Guide.module.css';
import { SITES, CURRENT_SITE_KEY } from '../../Constants';

const Guide = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSettings = () => {
    dispatch(GameActionCreators.activeScene('settings'));
  };

  const onLog = () => {
    dispatch(GameActionCreators.activeScene('log'));
  };

  const onPause = () => {
    dispatch(GameActionCreators.activeScene('pause'));
  };
  return (
    <div className={classes.GuideContainer}>
      <h1 className={classes.H1}>{t('guide.title')}</h1>
      <h2 className={classes.H2}>{t('guide.what.title')}</h2>
      <p>{t('guide.what.p1')}</p>
      <p>{t('guide.what.p2')}</p>
      <h2 className={classes.H2}>{t('guide.how.title')}</h2>
      <p>{t('guide.how.p1')}</p>
      <p>
        <Trans i18nKey="guide.how.p2">
          <b>1</b>
          <button
            className={classes.linkButton}
            type="button"
            onClick={onSettings}
          >
            1
          </button>
        </Trans>
      </p>
      <p>
        <Trans i18nKey="guide.how.p3" />
      </p>
      <p>{t('guide.how.p4')}</p>
      <p>{t('guide.how.p5')}</p>
      <h2 className={classes.H2}>{t('guide.rules.title')}</h2>
      <p>{t('guide.rules.p1')}</p>
      <ol>
        <Trans i18nKey="guide.rules.ol">
          <li>1</li>
          <li>1</li>
        </Trans>
      </ol>
      <p>{t('guide.rules.p2')}</p>
      <h2 className={classes.H2}>{t('guide.gamemodes.title')}</h2>
      <p>
        <Trans i18nKey="guide.gamemodes.p1" />
      </p>
      <p>
        <Trans i18nKey="guide.gamemodes.p2" />
      </p>
      <p>
        <Trans i18nKey="guide.gamemodes.p3" />
      </p>
      <p>
        <Trans i18nKey="guide.gamemodes.p4" />
      </p>
      <h2 className={classes.H2}>
        <FaCog />
        <button
          className={classes.iconLinkButton}
          type="button"
          onClick={onSettings}
        >
          {t('guide.settings.title')}
        </button>{' '}
      </h2>
      <p>
        <Trans i18nKey="guide.settings.p1" />
      </p>
      <p>
        <Trans i18nKey="guide.settings.p2" />
      </p>
      <p>
        <Trans i18nKey="guide.settings.p3" />
      </p>
      <p>
        <Trans i18nKey="guide.settings.p4" />
      </p>
      <p>
        <Trans i18nKey="guide.settings.p5" />
      </p>
      <p>
        <Trans i18nKey="guide.settings.p6" />
      </p>
      <ul>
        <Trans i18nKey="guide.settings.ul">
          <li>1</li>
          <li>
            <b>2</b>
          </li>
        </Trans>
      </ul>
      <p>
        <Trans i18nKey="guide.settings.p7" />
      </p>
      <p>
        <Trans i18nKey="guide.settings.p8" />
      </p>
      <p>
        <Trans i18nKey="guide.settings.p9" />
      </p>
      <p>
        <Trans i18nKey="guide.settings.p10" />
      </p>
      <p>
        <Trans i18nKey="guide.settings.p11" />
      </p>
      <h2 className={classes.H2}>
        <FaClipboardList />
        <button
          className={classes.iconLinkButton}
          type="button"
          onClick={onLog}
        >
          {t('guide.log.title')}
        </button>{' '}
      </h2>
      <p>{t('guide.log.p1')}</p>
      <h2 className={classes.H2}>
        <FaEdit />
        {t('guide.edit.title')}
      </h2>
      <p>{t('guide.edit.p1')}</p>
      <p>{t('guide.edit.p2')}</p>
      <h2 className={classes.H2}>{t('guide.wordmeaning.title')}</h2>
      <p>{t('guide.wordmeaning.p1')}</p>
      <h2 className={classes.H2}>
        <FaPauseCircle />
        {t('guide.pause.title')}
      </h2>
      <p>
        <Trans i18nKey="guide.pause.p1">
          <b>1</b>
          <button
            className={classes.linkButton}
            type="button"
            onClick={onPause}
          >
            1
          </button>
        </Trans>
      </p>
      <p>
        <Trans i18nKey="guide.pause.p2" />
      </p>
      <p>{t('guide.pause.p3')}</p>
      <h2 className={classes.H2}>{t('guide.words.title')}</h2>
      <p>{t('guide.words.p1')}</p>
      <p>
        <Trans i18nKey="guide.words.p2">
          <b>1</b>
          <a
            href={SITES[CURRENT_SITE_KEY].wordsSource}
            target="_blank"
            rel="noreferrer"
          >
            1
          </a>
        </Trans>
      </p>
      <h2 className={classes.H2}>{t('guide.feedback.title')}</h2>
      <p>{t('guide.feedback.p1')}</p>
    </div>
  );
};

export default function App() {
  return (
    <Suspense fallback="loading">
      <Guide />
    </Suspense>
  );
}
