import React, { Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as GameActionCreators from '../../actions/GameActionCreators';
import classes from './Footer.module.css';

const Footer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onShowTerms = () => {
    dispatch(GameActionCreators.showTerms(true));
  };

  return (
    <div className={classes.footerContainer}>
      <footer className={classes.footer}>
        <p className={classes.footerItem}>
          {`Copyright ${String.fromCharCode(169)} Selityspeli.com`}
        </p>
        <p className={`${classes.footerItem} ${classes.footerMiddle}`}>
          selityspeli@sinunsoft.com
        </p>
        <button
          type="button"
          className={classes.footerButton}
          onClick={onShowTerms}
        >
          {t('footer.terms')}
        </button>
      </footer>
    </div>
  );
};

export default function App() {
  return (
    <Suspense fallback="loading">
      <Footer />
    </Suspense>
  );
}
