import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as GameActionCreators from '../actions/GameActionCreators';

const Timer = () => {
  const dispatch = useDispatch();

  const { onGoing, time } = useSelector((state) => state.game);

  useEffect(() => {
    const interval = setInterval(() => {
      if (onGoing) {
        dispatch(GameActionCreators.secondPassed());
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [dispatch, onGoing]);

  return time;
};

export default Timer;
