import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classes from './Scores.module.css';

const Score = ({ always = false, black = false }) => {
  const {
    currentTeam,
    gameMode,
    totalPoints,
    turnPause,
    roundPause,
    finished,
    teams,
    time,
  } = useSelector((state) => state.game);
  const { t } = useTranslation();

  const renderRoundTotal = (roundScore, teamIndex) => {
    if (gameMode === 'time') {
      if (teamIndex !== currentTeam) {
        return '';
      }
      if (time >= 60) {
        const sec = time % 60;
        const min = (time - sec) / 60;
        return `+${min}min ${sec}s`;
      }
      return `+${time}s`;
    }
    return roundScore > 0 ? `+${roundScore}` : ' ';
  };

  const isWinner = (team, winnerScore, winnerTime) => {
    if (gameMode === 'time') {
      return team.totalTime === winnerTime;
    }
    return team.totalScore === winnerScore;
  };

  const renderRoundScore = (team, winnerScore, winnerTime, index) => (
    <div
      className={[
        classes.RoundAddition,
        gameMode === 'time' ? classes.TimeGameSmallFont : '',
        black ? classes.Black : '',
        index === currentTeam ? classes.CurrentTeamScore : '',
        isWinner(team, winnerScore, winnerTime) ? classes.WinnerTeam : '',
      ].join(' ')}
    >
      {renderRoundTotal(team.roundScore, index)}
    </div>
  );

  const renderTotal = (team) => {
    if (gameMode === 'time') {
      if (team.totalTime >= 60) {
        const sec = team.totalTime % 60;
        const min = (team.totalTime - sec) / 60;
        return `${min}min ${sec}s`;
      }
      return `${team.totalTime}s`;
    }
    return gameMode === 'rounds'
      ? team.totalScore
      : `${team.totalScore} / ${totalPoints}`;
  };

  const renderScore = (team, index, winnerScore, winnerTime) => (
    <React.Fragment key={index}>
      <div
        className={[
          classes.ScoreName,
          gameMode === 'time' ? classes.TimeGameSmallFont : '',
          black ? classes.Black : '',
          index === currentTeam ? classes.CurrentTeamScore : '',
          isWinner(team, winnerScore, winnerTime) ? classes.WinnerTeam : '',
        ].join(' ')}
      >
        {team.name ? team.name : t('scores.team', { id: team.id })}
      </div>
      <div
        className={[
          classes.ScoreTotal,
          gameMode === 'time' ? classes.TimeGameSmallFont : '',
          black ? classes.Black : '',
          index === currentTeam ? classes.CurrentTeamScore : '',
          isWinner(team, winnerScore, winnerTime) ? classes.WinnerTeam : '',
        ].join(' ')}
      >
        {renderTotal(team)}
      </div>
      {renderRoundScore(team, winnerScore, winnerTime, index)}
    </React.Fragment>
  );

  if ((turnPause || roundPause || always) && !finished) {
    const teamScores = teams.map((team, index) => renderScore(team, index));
    return <div className={classes.ScoresContainer}>{teamScores}</div>;
  }
  if (finished) {
    const sortedTeams = JSON.parse(JSON.stringify(teams));
    if (gameMode === 'time') {
      sortedTeams.sort((teamX, teamY) => teamX.totalTime - teamY.totalTime);
    } else {
      sortedTeams.sort((teamX, teamY) => teamY.totalScore - teamX.totalScore);
    }
    const winnerScore = sortedTeams[0].totalScore;
    const winnerTime = sortedTeams[0].totalTime;
    const teamScores = sortedTeams.map((team, index) =>
      renderScore(team, index, winnerScore, winnerTime),
    );
    return <div className={classes.ScoresContainer}>{teamScores}</div>;
  }
  return null;
};

export default function App() {
  return (
    <Suspense fallback="loading">
      <Score />
    </Suspense>
  );
}
