import React from 'react';
import classes from './Button.module.css';

import { TEAM_COLORS } from '../../Constants';

// TEsti onko build hajonnut täälläkin
const Button = ({
  children,
  onClick,
  settingsButton = false,
  settingsMode = false,
  disabled = false,
  colorIndex = 0,
}) => (
  <button
    type="button"
    className={[
      classes.Button,
      settingsButton ? classes.SettingsButton : '',
      settingsMode ? classes.SettingsMode : '',
    ].join(' ')}
    onClick={onClick}
    disabled={disabled}
    style={{ color: TEAM_COLORS[colorIndex] }}
  >
    {children}
  </button>
);

export default Button;
