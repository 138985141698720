import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  FaCog,
  FaClipboardList,
  FaPauseCircle,
  FaPlayCircle,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import * as GameActionCreators from '../../actions/GameActionCreators';
import IconButton from '../../components/common/IconButton';
import ChooseFlag from '../../components/ChooseFlag';
import Logo from '../../assets/explanationgame.png';
import classes from './Header.module.css';
import { SITES, CURRENT_SITE_KEY } from '../../Constants';

const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { activeScene } = useSelector((state) => state.game);

  const onGame = () => {
    dispatch(GameActionCreators.activeScene('game'));
  };

  const onSettings = () => {
    if (activeScene !== 'settings') {
      dispatch(GameActionCreators.activeScene('settings'));
    } else {
      dispatch(GameActionCreators.activeScene('game'));
    }
  };

  const onLog = () => {
    if (activeScene !== 'log') {
      dispatch(GameActionCreators.activeScene('log'));
    } else {
      dispatch(GameActionCreators.activeScene('game'));
    }
  };

  const onPause = () => {
    if (activeScene !== 'pause') {
      dispatch(GameActionCreators.activeScene('pause'));
    } else {
      dispatch(GameActionCreators.activeScene('game'));
    }
  };

  const onChangeLanguage = (selected) => {
    const site = Object.keys(SITES).find((key) => SITES[key].flag === selected);
    window.open(SITES[site].url);
  };

  return (
    <header className={classes.Header}>
      <div className={classes.TopHeader}>
        <div>
          <img
            className={classes.Image}
            src={Logo}
            width="24"
            heigth="24"
            alt="Selityspeli logo"
          />
          {t('header.title')}
        </div>
        <div className={classes.IconsContainer}>
          <IconButton onClick={onGame}>
            <FaPlayCircle />
          </IconButton>
          <IconButton onClick={onPause}>
            <FaPauseCircle />
          </IconButton>
          <IconButton onClick={onLog}>
            <FaClipboardList />
          </IconButton>
          <IconButton onClick={onSettings}>
            <FaCog />
          </IconButton>
        </div>
        <ChooseFlag
          selected={SITES[CURRENT_SITE_KEY].flag}
          options={Object.keys(SITES).map((key) => SITES[key].flag)}
          onSelected={onChangeLanguage}
        />
      </div>
    </header>
  );
};

export default function App() {
  return (
    <Suspense fallback="loading">
      <Header />
    </Suspense>
  );
}
