/* eslint-disable no-param-reassign */
import {
  ACTIVE_SCENE_CHANGED,
  WORD_CHANGED,
  GAME_STARTED,
  TIME_UPDATED,
  TURN_END,
  ROUND_START,
  TURN_START,
  WORD_GUESSED,
  WORD_PASSED,
  ALL_GUESS_CHANGED,
  TOTAL_ROUNDS_CHANGED,
  ROUND_TIME_CHANGED,
  FREEPASS_LIMIT_CHANGED,
  WORD_COUNT_CHANGED,
  WORD_MIN_LENGTH_CHANGED,
  WORD_MAX_LENGTH_CHANGED,
  TEAM_COUNT_CHANGED,
  TEAM_NAME_CHANGED,
  TEAM_COLOR_CHANGED,
  TOTAL_POINTS_CHANGED,
  GAME_MODE_CHANGED,
  SKIP_MODE_CHANGED,
  LOG_UPDATED,
  RESET_NOW,
  SHOW_TERMS,
  HISTORY_EDIT_MODE_CHANGED,
  LOG_EDITED,
  TIME_BONUS_CHANGED,
  SKIP_MINUS_TIME_CHANGED,
  ROUND_POINTS_CHANGED,
} from '../actions/types';
import { ALL_GUESS, SITES, CURRENT_SITE_KEY } from '../Constants';

const randomAllGuessChoice = () => {
  const randomAllGuessChoices = Object.keys(ALL_GUESS).map((mode) =>
    mode !== 'random' ? mode : null,
  );
  return randomAllGuessChoices[
    Math.floor(Math.random() * randomAllGuessChoices.length)
  ];
};

const INITIAL_STATE = {
  activeScene: 'game',
  onGoing: false,
  waitingLast: false,
  roundPause: false,
  turnPause: false,
  finished: false,
  currentWords: [SITES[CURRENT_SITE_KEY].siteName],
  log: [[[]]],
  words: [],
  wordCount: 1,
  wordLength: {
    from: 3,
    to: 12,
  },
  currentRound: 1,
  currentTeam: 0,
  gameMode: 'rounds',
  totalRounds: 2,
  totalPoints: 20,
  roundPoints: 10,
  roundPointsLeft: 10,
  time: 60,
  roundTime: 60,
  timeBonus: 15,
  skipMinusTime: 10,
  skipMode: 'never',
  freepassLimit: 2,
  freepassLeft: 2,
  allGuess: 'never',
  randomAllGuess: 'never',
  teams: [
    {
      id: 1,
      name: '',
      roundScore: 0,
      totalScore: 0,
      totalTime: 0,
      colorIndex: 0,
    },
    {
      id: 2,
      name: '',
      roundScore: 0,
      totalScore: 0,
      totalTime: 0,
      colorIndex: 1,
    },
  ],
  showTerms: false,
  historyEditMode: false,
};
const START_STATE = JSON.parse(JSON.stringify(INITIAL_STATE));

export default (state = START_STATE, action) => {
  switch (action.type) {
    case ACTIVE_SCENE_CHANGED:
      return { ...state, activeScene: action.payload, historyEditMode: false };
    case GAME_STARTED: {
      let { teams } = state;
      teams = teams.map((team) => {
        team.roundScore = 0;
        team.totalScore = 0;
        return team;
      });
      return {
        ...state,
        teams,
        onGoing: true,
        words: action.payload,
        currentWords: action.payload.slice(0, state.wordCount),
        waitingLast: false,
        roundPause: false,
        turnPause: false,
        finished: false,
        log: [[[]]],
        currentRound: 1,
        currentTeam: 0,
        time: state.gameMode === 'time' ? 0 : state.roundTime,
        roundPointsLeft: state.roundPoints,
        freepassLeft: state.freepassLimit,
        randomAllGuess: randomAllGuessChoice(),
        historyEditMode: false,
      };
    }
    case TIME_UPDATED: {
      const { time, onGoing, waitingLast } = action.payload;
      return { ...state, time, onGoing, waitingLast };
    }
    case WORD_CHANGED: {
      const { newWords, words } = action.payload;
      return { ...state, currentWords: newWords, words };
    }
    case TURN_END: {
      const { teams, roundPause, turnPause, finished } = action.payload;
      return {
        ...state,
        teams,
        waitingLast: false,
        roundPause,
        turnPause,
        finished,
        historyEditMode: false,
        roundPointsLeft: state.roundPoints,
      };
    }
    case ROUND_START: {
      const { teams, currentTeam, currentRound } = action.payload;
      return {
        ...state,
        teams,
        currentTeam,
        currentRound,
        roundPause: false,
        onGoing: true,
        historyEditMode: false,
        roundPointsLeft: state.roundPoints,
        time: state.gameMode === 'time' ? 0 : state.roundTime,
      };
    }
    case TURN_START: {
      const { teams, currentTeam, log } = action.payload;
      return {
        ...state,
        teams,
        currentTeam,
        turnPause: false,
        onGoing: true,
        freepassLeft: state.freepassLimit,
        randomAllGuess: randomAllGuessChoice(),
        log,
        historyEditMode: false,
        roundPointsLeft: state.roundPoints,
        time: state.gameMode === 'time' ? 0 : state.roundTime,
      };
    }
    case WORD_GUESSED: {
      const { teams, log, time, roundPointsLeft, onGoing } = action.payload;
      return {
        ...state,
        teams,
        log,
        time,
        roundPointsLeft,
        onGoing,
      };
    }
    case WORD_PASSED: {
      const {
        teams,
        freepassLeft,
        log,
        time,
        roundPointsLeft,
      } = action.payload;
      return { ...state, teams, freepassLeft, log, time, roundPointsLeft };
    }
    case ALL_GUESS_CHANGED:
      return { ...state, allGuess: action.payload };
    case SKIP_MODE_CHANGED:
      return { ...state, skipMode: action.payload };
    case TOTAL_ROUNDS_CHANGED:
      return { ...state, totalRounds: action.payload };
    case TOTAL_POINTS_CHANGED:
      return { ...state, totalPoints: action.payload };
    case ROUND_TIME_CHANGED: {
      let { time } = state;
      if (time === state.roundTime) {
        time = action.payload;
      }
      return { ...state, roundTime: action.payload, time };
    }
    case FREEPASS_LIMIT_CHANGED:
      return {
        ...state,
        freepassLimit: action.payload,
        freepassLeft: action.payload,
      };
    case WORD_COUNT_CHANGED:
      return { ...state, wordCount: action.payload };
    case WORD_MIN_LENGTH_CHANGED: {
      const { wordLength } = state;
      wordLength.from = action.payload;
      return { ...state, wordLength };
    }
    case WORD_MAX_LENGTH_CHANGED: {
      const { wordLength } = state;
      wordLength.to = action.payload;
      return { ...state, wordLength };
    }
    case TEAM_COUNT_CHANGED:
      return { ...state, teams: JSON.parse(JSON.stringify(action.payload)) };
    case TEAM_NAME_CHANGED:
      return { ...state, teams: action.payload };
    case TEAM_COLOR_CHANGED:
      return { ...state, teams: JSON.parse(JSON.stringify(action.payload)) };
    case GAME_MODE_CHANGED:
      return {
        ...state,
        gameMode: action.payload,
        time: action.payload === 'time' ? 0 : state.roundTime,
      };
    case LOG_UPDATED:
      return { ...state, log: action.payload };
    case LOG_EDITED: {
      const { log, teams } = action.payload;
      return { ...state, log, teams };
    }
    case SHOW_TERMS:
      return { ...state, showTerms: action.payload };
    case HISTORY_EDIT_MODE_CHANGED:
      return { ...state, historyEditMode: action.payload };
    case TIME_BONUS_CHANGED:
      return { ...state, timeBonus: action.payload };
    case SKIP_MINUS_TIME_CHANGED:
      return { ...state, skipMinusTime: action.payload };
    case ROUND_POINTS_CHANGED:
      return { ...state, roundPoints: action.payload };
    case RESET_NOW:
      return JSON.parse(JSON.stringify(INITIAL_STATE));
    default:
      return state;
  }
};
