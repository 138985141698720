import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import * as GameActionCreators from '../../actions/GameActionCreators';
import Header from '../Header/Header';
import Scores from '../Scores/Scores';
import TurnLog from '../TurnLog/TurnLog';
import IconButton from '../../components/common/IconButton';
import Guide from '../Guide/Guide';
import Terms from '../Terms/Terms';
import Footer from '../Footer/Footer';
import MobileMenubar from '../MobileMenubar/MobileMenubar';
import classes from './Log.module.css';
import { TEAM_COLORS } from '../../Constants';

const Log = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { teams, currentTeam, log } = useSelector((state) => state.game);

  const onClose = () => {
    dispatch(GameActionCreators.activeScene('game'));
  };

  const renderTurn = (turn, turnIndex, roundIndex) => (
    <TurnLog
      key={roundIndex.toString() + turnIndex.toString()}
      turnLog={turn}
      turnLogIndex={turnIndex}
      roundLogIndex={roundIndex}
      closable
    />
  );

  const renderTurns = (round, roundIndex) =>
    round.map((turn, index) => renderTurn(turn, index, roundIndex));

  const renderRound = (round, index) => renderTurns(round, index);

  const renderRounds = () =>
    log.map((round, index) => renderRound(round, index));

  return (
    <div
      className={classes.Container}
      style={{ backgroundColor: TEAM_COLORS[teams[currentTeam].colorIndex] }}
    >
      <Header />
      <div className={classes.LogContainer}>
        <div className={classes.Title}>
          {t('log.gamescore')}
          <IconButton onClick={onClose}>
            <FaTimes />
          </IconButton>
        </div>
        <Scores always black />
      </div>
      {renderRounds()}
      <Guide />
      <Terms />
      <Footer />
      <MobileMenubar />
    </div>
  );
};

export default function App() {
  return (
    <Suspense fallback="loading">
      <Log />
    </Suspense>
  );
}
