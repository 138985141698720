import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaTimes, FaEdit } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import * as GameActionCreators from '../../actions/GameActionCreators';
import IconButton from '../../components/common/IconButton';
import classes from './TurnLog.module.css';
import { TEAM_COLORS, SITES, CURRENT_SITE_KEY } from '../../Constants';

const TurnLog = ({
  turnLog = false,
  turnLogIndex = false,
  roundLogIndex = false,
  summary = false,
  closable = false,
}) => {
  const dispatch = useDispatch();
  const { teams, log, currentTeam, historyEditMode, gameMode } = useSelector(
    (state) => state.game,
  );
  const { t } = useTranslation();

  const roundIndex = roundLogIndex === false ? log.length - 1 : roundLogIndex;
  const turnIndex =
    turnLogIndex === false ? log[roundIndex].length - 1 : turnLogIndex;

  const getLastTurnLogData = () => {
    if (log && log.length > 0) {
      const lastRound = log[log.length - 1];
      if (lastRound && lastRound.length > 0) {
        return lastRound[lastRound.length - 1];
      }
    }
    return '';
  };
  const turn = turnLog || getLastTurnLogData();

  const turnScores = {};
  let turnTime = 0;

  const onTogglePoints = (logItemIndex) => {
    dispatch(
      GameActionCreators.toggleLogItemPoints(
        logItemIndex,
        turnIndex,
        roundIndex,
      ),
    );
  };

  const onClose = () => {
    dispatch(GameActionCreators.activeScene('game'));
  };

  const onToggleTeam = (logItemIndex) => {
    dispatch(
      GameActionCreators.toggleLogItemTeam(logItemIndex, turnIndex, roundIndex),
    );
  };

  const onEditMode = () => {
    dispatch(GameActionCreators.toggleEditMode());
  };

  const renderCommaSpan = (condition) => {
    if (condition) {
      return <span>{', '}</span>;
    }
    return null;
  };

  const renderWord = (word, isLast) => (
    <React.Fragment key={word}>
      <a
        key={word}
        href={SITES[CURRENT_SITE_KEY].wordsLink + word}
        target="_blank"
        rel="noreferrer"
      >
        {word}
      </a>
      {renderCommaSpan(!isLast)}
    </React.Fragment>
  );

  const renderPoints = (logItem) => {
    if (gameMode === 'time') {
      if (logItem.explanationTime >= 60) {
        const sec = logItem.explanationTime % 60;
        const min = (logItem.explanationTime - sec) / 60;
        return `+${min}min ${sec}s`;
      }
      return logItem.explanationTime > 0
        ? `+${logItem.explanationTime}s`
        : '0s';
    }
    return logItem.points > 0 ? `+${logItem.points}` : logItem.points;
  };

  const renderLogItem = (logItem, index) => {
    const teamName = teams[logItem.team].name
      ? teams[logItem.team].name
      : t('turnlog.team', { id: logItem.team + 1 });
    if (logItem.points > 0) {
      turnScores[teamName] = turnScores[teamName]
        ? turnScores[teamName] + logItem.points
        : logItem.points;
    }
    turnTime += logItem.explanationTime;
    const scoreLenth =
      gameMode === 'time' ? logItem.explanationTime : logItem.points;
    return (
      <React.Fragment key={`${index}log`}>
        <span className={classes.GridItem}>
          {logItem.words.map((word, i) =>
            renderWord(word, i === logItem.words.length - 1),
          )}
        </span>
        <button
          disabled={!historyEditMode}
          type="button"
          onClick={() => onToggleTeam(index)}
          className={[
            classes.GridButton,
            historyEditMode ? classes.ActiveGridButton : '',
            classes.CenterGrid,
          ].join(' ')}
        >
          {teamName}
        </button>
        <button
          disabled={!historyEditMode}
          type="button"
          onClick={() => onTogglePoints(index)}
          className={[
            classes.GridButton,
            historyEditMode ? classes.ActiveGridButton : '',
            scoreLenth === 0 ? classes.zeroPoints : '',
            scoreLenth > 0 ? classes.plusPoints : '',
            scoreLenth < 0 ? classes.minusPoints : '',
          ].join(' ')}
        >
          {renderPoints(logItem)}
        </button>
      </React.Fragment>
    );
  };

  const renderTurnTotal = (teamScore) => {
    if (gameMode === 'time') {
      if (teamScore >= 60) {
        const sec = teamScore % 60;
        const min = (teamScore - sec) / 60;
        return `+${min}min ${sec}s`;
      }
      return teamScore > 0 ? `${teamScore}s` : teamScore;
    }
    return teamScore > 0 ? `+${teamScore}` : teamScore;
  };

  const renderTurnScore = (teamName, teamScore, index) => (
    <React.Fragment key={`${index}score`}>
      <span
        key={`${index}sum`}
        className={[
          classes.ScoreGridItem,
          index === 0 ? classes.FirstScore : '',
        ].join(' ')}
      >
        {index === 0 ? t('turnlog.total') : ''}
      </span>
      <span
        key={`${index}teamsum`}
        className={[
          classes.ScoreGridItem,
          index === 0 ? classes.FirstScore : '',
        ].join(' ')}
      >
        {teamName}
      </span>
      <span
        key={`${index}pointssum`}
        className={[
          classes.ScoreGridItem,
          index === 0 ? classes.FirstScore : '',
          teamScore === 0 ? classes.zeroPoints : '',
          teamScore > 0 ? classes.plusPoints : '',
          teamScore < 0 ? classes.minusPoints : '',
        ].join(' ')}
      >
        {renderTurnTotal(teamScore)}
      </span>
    </React.Fragment>
  );

  const renderCloseIcon = () => {
    if (closable) {
      return (
        <IconButton onClick={onClose}>
          <FaTimes />
        </IconButton>
      );
    }
    return null;
  };

  const currentTurnTeamName = teams[turnIndex].name
    ? teams[turnIndex].name
    : t('turnlog.team', { id: turnIndex + 1 });

  const renderTitle = () => {
    if (summary) {
      return t('turnlog.summary');
    }
    return t('turnlog.round', {
      round: roundIndex + 1,
      team: currentTurnTeamName,
    });
  };

  const renderTotals = () => {
    if (gameMode === 'time') {
      return renderTurnScore(currentTurnTeamName, turnTime, 0);
    }
    return Object.keys(turnScores).map((teamName, index) =>
      renderTurnScore(teamName, turnScores[teamName], index),
    );
  };

  const renderEditIcon = () => {
    if (gameMode !== 'time') {
      return (
        <IconButton onClick={onEditMode}>
          <FaEdit
            style={
              historyEditMode
                ? { color: TEAM_COLORS[teams[currentTeam].colorIndex] }
                : {}
            }
          />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <div className={classes.LogContainer}>
      <div className={classes.Title}>
        {renderTitle()}
        <div
          className={[
            classes.IconsContainer,
            closable ? '' : classes.SingleIcon,
          ].join(' ')}
        >
          {renderEditIcon()}
          {renderCloseIcon()}
        </div>
      </div>
      <div className={classes.LogItemsContainer}>
        {turn.map((logItem, index) => renderLogItem(logItem, index))}
        {renderTotals()}
      </div>
    </div>
  );
};

export default function App() {
  return (
    <Suspense fallback="loading">
      <TurnLog />
    </Suspense>
  );
}
