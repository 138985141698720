export const ACTIVE_SCENE_CHANGED = 'active_scene_changed';
export const WORD_CHANGED = 'word_changed';
export const GAME_STARTED = 'game_started';
export const TIME_UPDATED = 'time_updated';
export const TURN_END = 'turn_end';
export const ROUND_START = 'round_start';
export const TURN_START = 'turn_start';
export const WORD_GUESSED = 'word_guessed';
export const WORD_PASSED = 'word_passed';
export const ALL_GUESS_CHANGED = 'all_guess_changed';
export const TOTAL_ROUNDS_CHANGED = 'total_rounds_changed';
export const ROUND_TIME_CHANGED = 'round_time_changed';
export const FREEPASS_LIMIT_CHANGED = 'freepass_limit_changed';
export const WORD_COUNT_CHANGED = 'word_count_changed';
export const WORD_MIN_LENGTH_CHANGED = 'word_min_length_changed';
export const WORD_MAX_LENGTH_CHANGED = 'word_max_length_changed';
export const TEAM_COUNT_CHANGED = 'team_count_changed';
export const TEAM_NAME_CHANGED = 'team_name_changed';
export const TEAM_COLOR_CHANGED = 'team_color_changed';
export const TOTAL_POINTS_CHANGED = 'total_points_changed';
export const GAME_MODE_CHANGED = 'game_mode_changed';
export const SKIP_MODE_CHANGED = 'skip_mode_changed';
export const LOG_UPDATED = 'log_updated';
export const RESET_NOW = 'reset_now';
export const SHOW_TERMS = 'show_terms';
export const HISTORY_EDIT_MODE_CHANGED = 'history_edit_mode_changed';
export const LOG_EDITED = 'log_edited';
export const TIME_BONUS_CHANGED = 'time_bonus_changed';
export const SKIP_MINUS_TIME_CHANGED = 'skip_minus_time_changed';
export const ROUND_POINTS_CHANGED = 'round_points_changed';
