import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Timer from '../../components/Timer';
import classes from './GameHeader.module.css';

const GameHeader = () => {
  const {
    waitingLast,
    currentRound,
    totalRounds,
    teams,
    currentTeam,
    freepassLeft,
    allGuess,
    randomAllGuess,
    gameMode,
    skipMode,
    roundPointsLeft,
    roundPoints,
  } = useSelector((state) => state.game);
  const { t } = useTranslation();

  const showAllGuessed = (allGuessMode) =>
    allGuessMode === 'always' ||
    (allGuessMode === 'end' && waitingLast) ||
    (allGuessMode === 'lastRound' && currentRound === totalRounds);

  const renderAllGuessMode = () => {
    if (
      showAllGuessed(allGuess) ||
      (allGuess === 'random' && showAllGuessed(randomAllGuess))
    ) {
      return (
        <div className={classes.AllGuessHeader}>{t('gameheader.allguess')}</div>
      );
    }
    return null;
  };

  const renderWhoseTurn = (team) => (
    <div className={classes.BottomHeader}>
      <div className={classes.CurrentPlayer}>
        {team.name ? team.name : t('gameheader.currentteam', { id: team.id })}
      </div>
    </div>
  );

  const renderFreepass = () => {
    if (skipMode === 'freepass') {
      return (
        <div className={classes.HeaderItem}>
          {t('gameheader.freepasses')}
          <div className={classes.Freepass}>{freepassLeft}</div>
        </div>
      );
    }
    return null;
  };

  const renderPoints = () => {
    if (gameMode === 'time') {
      return (
        <div className={classes.HeaderItem}>
          {t('gameheader.timeleft')}
          <div className={classes.RoundScore}>
            {`${roundPointsLeft} / ${roundPoints}`}
          </div>
        </div>
      );
    }
    return (
      <div className={classes.HeaderItem}>
        {t('gameheader.roundpoints')}
        <div className={classes.RoundScore}>
          {teams[currentTeam].roundScore}
        </div>
      </div>
    );
  };

  return (
    <>
      {renderWhoseTurn(teams[currentTeam])}
      <div className={classes.MainHeader}>
        <div className={classes.HeaderItem}>
          {t('gameheader.round')}
          <div className={classes.Round}>
            {gameMode === 'points'
              ? currentRound
              : `${currentRound} / ${totalRounds}`}
          </div>
        </div>
        <div className={classes.HeaderItem}>
          {t('gameheader.time')}
          <div className={classes.TimeLeft}>
            <Timer />
          </div>
        </div>
        {renderFreepass()}
        {renderPoints()}
      </div>
      {renderAllGuessMode()}
    </>
  );
};

export default function App() {
  return (
    <Suspense fallback="loading">
      <GameHeader />
    </Suspense>
  );
}
